<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toTypeUrl('applet_list_detail_set')">配置信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">基本信息</h5>
      <el-button @click="toTypeUrl('applet_list_add')" class="editBt" type="primary"
        v-if="detail.appType != 'QUICK_CREATE' && detail.appType != 'MERCHANT'">编辑</el-button>
    </div>
    <!-- 为快速创建时展示的基本信息 -->
    <div class="list clear" v-if="detail.appType == 'QUICK_CREATE' || detail.appType == 'MERCHANT'">
      <ul class="listBox">
        <li style="height: 68px">
          <span>小程序头像</span>
          <i class="hidden1">
            <img v-if="detail.avatar" :src="detail.avatar" alt="加载失败" />
            <span v-else style="display: inline-block; height: 68px"></span>
          </i>
        </li>
        <li>
          <span>小程序名称</span>
          <i class="hidden1">{{ detail.appName ? detail.appName : "" }}</i>
        </li>
        <li>
          <span>所属第三方</span>
          <i class="hidden1">{{ detail.thirdName ? detail.thirdName : "" }}</i>
        </li>
        <li>
          <span>小程序主体</span>
          <i class="hidden1">{{ detail.principalName }}</i>
        </li>
        <li>
          <span>创建方式</span>
          <i class="hidden1">快速创建</i>
        </li>
        <li>
          <span>小程序APPID</span>
          <i class="hidden1">{{ detail.appId }}</i>
        </li>
        <li>
          <span>小程序原始ID</span>
          <i class="hidden1">
            {{ detail.originalId ? detail.originalId : "" }}
            <a @click="searchId" href="javascript:;" style="margin-left: 20px">查询</a>
          </i>
        </li>
        <li>
          <span>企业代码类型</span>
          <i class="hidden1" v-if="detail.enterpriseCodeType == 1">统一社会信用代码</i>
          <i class="hidden1" v-if="detail.enterpriseCodeType == 2">组织机构代码</i>
          <i class="hidden1" v-if="detail.enterpriseCodeType == 3">营业执照注册号(</i>
        </li>
        <li>
          <span>企业代码</span>
          <i class="hidden1">{{ detail.enterpriseCode }}</i>
        </li>
        <li>
          <span>法人姓名</span>
          <i class="hidden1">{{ detail.legalPersonName }}</i>
        </li>
        <li>
          <span>法人微信</span>
          <i class="hidden1">{{ detail.legalPersonWechat }}</i>
        </li>
        <li>
          <span>类目</span>
          <i class="hidden1">
            {{ detail.firstCategory ? detail.firstCategory : ""
            }}{{ detail.secondCategory ? "/" + detail.secondCategory : ""
            }}{{ detail.thirdCategory ? "/" + detail.thirdCategory : "" }}
          </i>
        </li>
        <li>
          <span>介绍</span>
          <i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.signature" placement="top">
              <span>{{ detail.signature }}</span>
            </el-tooltip>
          </i>
        </li>
        <li>
          <span>创建时间</span>
          <i class="hidden1">{{ detail.createTime }}</i>
        </li>
      </ul>
    </div>

    <!-- 为复用创建时展示的基本信息 -->
    <div class="list clear" v-if="detail.appType == 'REUSE'">
      <ul class="listBox">
        <li style="height: 68px">
          <span>小程序头像</span>
          <i class="hidden1">
            <img v-if="detail.avatar" :src="detail.avatar" alt="加载失败" />
            <span v-else style="display: inline-block; height: 68px"></span>
          </i>
        </li>
        <li>
          <span>小程序名称</span>
          <i class="hidden1">{{ detail.appName ? detail.appName : "" }}</i>
        </li>
        <li>
          <span>所属第三方</span>
          <i class="hidden1">{{ detail.thirdName ? detail.thirdName : "" }}</i>
        </li>
        <li>
          <span>小程序主体</span>
          <i class="hidden1">{{ detail.principalName }}</i>
        </li>
        <li>
          <span>authorizer_refresh_token</span>
          <i class="hidden1">
            {{
              detail.authorizerRefreshToken ? detail.authorizerRefreshToken : ""
            }}
          </i>
        </li>
        <li>
          <span>创建方式</span>
          <i class="hidden1">复用创建</i>
        </li>
        <li>
          <span>公众号APPID</span>
          <i class="hidden1">{{ detail.wxAppId }}</i>
        </li>
        <li>
          <span>小程序APPID</span>
          <i class="hidden1">
            {{ detail.appId ? detail.appId : "" }}
            <a v-if="!detail.appId" @click="openChange" href="javascript:;" style="margin-left: 20px">变更</a>
          </i>
        </li>
        <li>
          <span>小程序原始ID</span>
          <i class="hidden1">
            {{ detail.originalId ? detail.originalId : "" }}
            <a @click="searchId" href="javascript:;" style="margin-left: 20px">查询</a>
          </i>
        </li>
        <li>
          <span>类目</span>
          <i class="hidden1">
            {{ detail.firstCategory ? detail.firstCategory : ""
            }}{{ detail.secondCategory ? "/" + detail.secondCategory : ""
            }}{{ detail.thirdCategory ? "/" + detail.thirdCategory : "" }}
          </i>
        </li>
        <li>
          <span>介绍</span>
          <i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.signature" placement="top">
              <span>{{ detail.signature }}</span>
            </el-tooltip>
          </i>
        </li>
        <li>
          <span>创建时间</span>
          <i class="hidden1">{{ detail.createTime }}</i>
        </li>
      </ul>
    </div>

    <!-- 为商户创建时展示的基本信息 -->
    <!-- <div class="list clear" v-show="detail.appType == 'MERCHANT'">
      <ul class="listBox">
        <li>
          <span>小程序名称</span>
          <i class="hidden1">{{ detail.appName }}</i>
        </li>
        <li>
          <span>所属第三方</span>
          <i class="hidden1">{{ detail.thirdName ? detail.thirdName : "" }}</i>
        </li>
        <li>
          <span>小程序主体</span>
          <i class="hidden1">{{ detail.principalName }}</i>
        </li>
        <li>
          <span>APPsecret</span>
          <i class="hidden1">{{ detail.appSecret }}</i>
        </li>
        <li>
          <span>APPID</span>
          <i class="hidden1">{{ detail.appId }}</i>
        </li>
        <li>
          <span>原始ID</span>
          <i class="hidden1">{{ detail.originalId ? detail.originalId : "" }}</i>
        </li>
        <li>
          <span>创建时间</span>
          <i class="hidden1">{{ detail.createTime }}</i>
        </li>
      </ul>
    </div> -->

    <div style="text-align: center">
      <!-- <el-button @click="codeImg" class="next" type="primary" v-show="detail.appType=='REUSE'&&detail.verifyStatus===true">生成授权页</el-button> -->
      <el-button class="next" type="primary" v-show="detail.appType == 'REUSE' && !detail.verifyStatus">
        <a :href="detail.authCallbackUrl">生成授权页</a>
      </el-button>
      <el-button class="next" type="primary" v-show="detail.appType == 'REUSE' && !detail.verifyStatus">
        <a :href="detail.createCallbackUrl">快速创建</a>
      </el-button>
    </div>
    <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
      <a href="javascript:;">
        <img :src="showImgUrl" alt="加载失败" />
      </a>
    </div>
    <!-- 变更弹窗 -->
    <el-dialog title="变更小程序" @close="changeType = false" :visible="changeType" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>小程序appid</div>
          <el-input v-model="formData.appId" maxlength="128" placeholder="输入小程序APPID"></el-input>
          <div class="overAccount_ul_title">小程序认证状态:</div>
          <el-input v-if="formData.verifyStatus" v-model="formData.verifyStatus" maxlength="128" disabled></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>小程序刷新令牌</div>
          <el-input v-model="formData.refreshToken" maxlength="128" placeholder="输入小程序刷新令牌"></el-input>
          <div class="overAccount_ul_title">小程序主体名称:</div>
          <el-input v-if="formData.principalName" v-model="formData.principalName" maxlength="128" disabled></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="addBt" @click="changeApplet(1)">检 验</el-button>
        <el-button class="addBt" @click="changeApplet(2)">变 更</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getApps,
  getOriginid,
  getAppsAuthqrcode,
  testOrChange,
} from "@/api/user/applet.js";
export default {
  data() {
    return {
      detail: "",
      showImgUrl: "",
      changeType: false, //控制变更弹窗
      formData: {
        appId: "", //（小程序appid）
        refreshToken: "", //（小程序刷新令牌）
        verifyStatus: "", //小程序认证状态 1：实名验证成功，2：实名验证中，3：实名验证失败
        principalName: "", //小程序主体名称
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      getApps(this.$route.query.id).then((res) => {
        if (res) {
          this.detail = res.resultData;
          console.log("详情数据为", this.detail);
        }
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          status: this.detail.appType,
        },
      });
    },
    searchId() {
      getOriginid(this.$route.query.id).then((res) => {
        if (res) {
          this.detail.originalId = res.resultData;
          this.$message.success("查询成功");
        }
      });
    },
    // 授权
    codeImg() {
      getAppsAuthqrcode(this.$route.query.id).then((res) => {
        if (res) {
          this.showImgUrl =
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
        }
      });
    },
    // 去授权
    toVerify() {
      location.href = this.detail.authCallbackUrl;
    },
    back() {
      this.$router.push("/user/businessList_shlb/addcommercial_jbxx");
    },
    next() {
      this.$router.push("/user/businessList_shlb/addcommercial_qdxx");
    },

    // 点击打开变更弹窗
    openChange() {
      for (var i in this.formData) {
        this.formData[i] = "";
      }
      this.changeType = true;
    },
    // 检验/变更小程序
    changeApplet(val) {
      if (!this.formData.appId) {
        this.$message.error("请输入小程序APPID");
        return;
      }
      if (!this.formData.refreshToken) {
        this.$message.error("请输入小程序刷新令牌");
        return;
      }
      let type = "";
      if (val == 1) {
        //检验小程序
        type = 0;
      } else {
        //变更小程序
        type = 1;
      }
      let params = {
        appId: this.formData.appId.trim(),
        refreshToken: this.formData.refreshToken.trim(),
        type,
      };
      // let arr = !Object.values(this.formData).every((item) => item === ""); //防止重复新增
      // if (arr) {
      // }
      this._testOrChange(params, val);
    },

    // 检验/变更接口
    _testOrChange(params, val) {
      if (val == 1) {
        testOrChange(this.$route.query.id, params).then((res) => {
          if (res.resultStatus) {
            this.$message.success("检验成功");
            this.formData.principalName = res.resultData.principalName;
            let status = res.resultData.verifyStatus;
            if (status == 1) {
              this.formData.verifyStatus = "实名验证成功";
            } else if (status == 2) {
              this.formData.verifyStatus = "实名验证中";
            } else {
              this.formData.verifyStatus = "实名验证失败";
            }
          }
        });
      } else {
        //  变更小程序
        if (this.formData.verifyStatus == "实名验证成功") {
          testOrChange(this.$route.query.id, params).then((res) => {
            if (res.resultStatus) {
              this.$message.success("变更成功");
              this.changeType = false;
              this.getDetail();
            }
          });
        } else {
          this.$message.error("校验失败,请重新检验");
        }
      }
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";

.listBox li:nth-child(2n+1) {
  margin-left: 8%;
}

.listBox li:nth-child(1) {
  margin-left: 0%;
  width: 100%;
}

.listBox li:nth-child(2n) {
  margin-left: 0%;
}

.listBox i img {
  height: 68px;
  width: auto;
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.next a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 30px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
}


.list .left_con h5 img {
  width: 50px;
  height: 50px;
  margin-bottom: 18px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  /* margin-bottom: 10px; */
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
  margin-top: 40px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
